import React from 'react'

import SEO from 'components/elements/SEO/SEO'
import ProductList from 'components/modules/ProductList/ProductList'

export default function ProductListTemplate() {
  return (
    <>
      <SEO title="Corsi Online" keywords={['SEO0', 'SEO1', 'SEO2']} />
      <section className="our-products closer">
        <h3 className="title">Corsi Online</h3>
        <p className="intro-products">I nostri 5 buoni motivi:</p>
        <ul className="intro-products">
          <li>
            <b>
              <em>Economicità</em>&nbsp;
            </b>
            = da un’analisi di mercato riusciamo ad essere tra i players più economici del mercato;
          </li>
          <li>
            <b>
              <em>Semplicità</em>&nbsp;
            </b>
            = i nostri corsi sono caratterizzati da grande immediatezza e semplicità e sono ricchi di immagini e fumetti
            esplicativi. I nostri corsi si fanno seguire con curiosità e passione.
          </li>
          <li>
            <b>
              <em>Competenza</em>&nbsp;
            </b>
            = i nostri corsi sono stati redatti da professionisti della Safety che lavorano sul campo quotidianamente al
            fianco dei lavoratori da oltre 25 anni per tantissime aziende piccole, medie e grandi su tutto il territorio
            nazionale
          </li>
          <li>
            <b>
              <em>Autorizzazione</em>&nbsp;
            </b>
            = i nostri corsi sono gestiti ed erogati in collaborazione con GESFOR, <b>Ente di Formazione</b> che opera
            dal 1998 nella Formazione e nell’Orientamento Professionale nonché nelle politiche del lavoro, con finalità
            educative e sociali, anche <b>APL</b>, iscritta all’Albo Informatico delle Agenzie del Lavoro che svolgono
            attività di Intermediazione ed autorizzata anche per l’attività di somministrazione, accreditata per
            l’erogazione dei Servizi per il Lavoro sul territorio nazionale accreditato regionalmente e con sedi fisiche
            di supporto in <b>30 città italiane</b>.
          </li>
          <li>
            <b>
              <em>Controllo</em>&nbsp;
            </b>
            = i nostri corsi sono controllati da un Comitato Scientifico costituito da professionisti della Safety,
            della Medicina del lavoro, della Formazione con esperienza ultraventennale.
          </li>
        </ul>
        <br />
        <ProductList />
      </section>
    </>
  )
}
