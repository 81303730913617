import React from 'react'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Typography from '@material-ui/core/Typography'

import { useStaticQuery, graphql } from 'gatsby'

import { useThemeContext } from 'context/ThemeContext'
import Link from 'components/foundations/Link/Link'
import CustomImage from 'components/foundations/Image/CustomImage'

export default function ProductList() {
  const ProductsQuery = Object.values(
    useStaticQuery(graphql`
      query ProductQuery {
        allSitePage(filter: { path: { regex: "/^/i-nostri-corsi/" } }, sort: { order: ASC, fields: path }) {
          nodes {
            path
          }
        }
      }
    `).allSitePage.nodes,
  )

  const productURIs = ProductsQuery.map((paths) => Object.values(paths).toString())

  function splitName(string) {
    return string.split('/')[4]
  }

  function capitalise(string) {
    return string
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
  }

  const { isMobile } = useThemeContext()

  return (
    <>
      <section className="our-products closer">
        <p className="product-subtitle">
          <span>Richiedi un preventivo per acquisti di prodotti in quantità maggiore.</span>
          <Link to="/contatti">
            <span className="btn product-btn">Richiedi preventivo</span>
          </Link>
        </p>
        <GridList cols={isMobile ? 1 : 4} cellHeight="auto" spacing={30} className="gridList closer">
          {productURIs.map((productURL) => (
            <GridListTile className="single-product" key={splitName(productURL)}>
              <Link to={productURL}>
                <CustomImage filename={`${`${splitName(productURL)}`}.jpg`} />
                <div className="overlay-product">
                  <span className="btn btn-product">Acquista</span>
                </div>
                <Typography className="product-info" component="p">
                  {capitalise(splitName(productURL).replace(/-/g, ' '))}
                </Typography>
                {/* <ProductPrice productPrice={products[variantSelected].productPrice} /> */}
              </Link>
            </GridListTile>
          ))}
        </GridList>
      </section>
    </>
  )
}
